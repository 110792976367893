import classes from './VideoList.module.css';
import Video from './Video';
import { useSelector, useDispatch } from 'react-redux';
import { HomeActions } from '../../store/homeSlice';
import { useEffect, useCallback, useState } from 'react';
import { homeURLs } from '../../urls/publicURLs';

const VideoList = () => {
  const state = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = useState(1); // Page number state
  const [totalPages, setTotalPages] = useState(0); // Set initial total pages
  const [loading, setLoading] = useState(false); // Loading state for buttons

  // Fetch videos based on page number
  const fetchVideos = useCallback(
    async (page) => {
      setLoading(true); // Start loading when fetching begins
      const filters = state.filters ? JSON.parse(state.filters) : {};

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: filters ? JSON.stringify(filters) : null,
      };

      try {
        const response = await fetch(
          `${homeURLs.allVideos}?PageNumber=${page}&PageSize=10`,
          options
        );
        const resData = await response.json();
        const videos = resData?.data?.data || [];
        const numPages = resData?.data?.totalPages; // Get total pages from response

        if (videos.length > 0) {
          dispatch(HomeActions.setVideos(videos)); // Replace the video list with new videos
          setTotalPages(numPages); // Set total pages
        } else {
          if (page === 1) {
            dispatch(HomeActions.setVideos('noVideo')); // No videos found on the first page
          } else {
            dispatch(HomeActions.setVideos([])); // No more videos
          }
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        setLoading(false); // Stop loading after fetch completes
      }
    },
    [dispatch, state.filters]
  );

  // Fetch initial videos on component mount
  useEffect(() => {
    dispatch(HomeActions.resetVideos()); // Clear previous videos
    setPageNum(1); // Reset page number
    fetchVideos(1); // Fetch first page
  }, [dispatch, fetchVideos]);

  // Fetch new videos when pageNum changes
  useEffect(() => {
    // Clear existing videos when page changes
    dispatch(HomeActions.resetVideos());
    fetchVideos(pageNum); // Fetch videos for the new page
  }, [pageNum, fetchVideos, dispatch]);

  // Next and Previous page functions
  const nextPage = () => {
    if (pageNum < totalPages) {
      setPageNum((prevPage) => prevPage + 1); // Increment page number
    }
  };

  const previousPage = () => {
    if (pageNum > 1) {
      setPageNum((prevPage) => prevPage - 1); // Decrement page number
    }
  };

  // Handle "No results found" case
  if (state.noMoreVideos && state.videoList.length === 0) {
    return (
      <div className={classes.videoNoResult}>
        <p className={classes.videoNoResult_h}>No result found!</p>
        <a href="/" className={classes.videoNoResult_brief}>
          Check other videos
        </a>
      </div>
    );
  }

  return (
    <div className={classes.videoList}>
      {state.videoList.length === 0 && !state.noMoreVideos && (
        <div className={classes.videoListItem}>
          <Video
            data={{
              videoUrl: 'Please wait...',
              dealType: 'Please wait...',
              noOfBedRooms: 'Please wait...',
              noOfBathRooms: 'Please wait...',
              price: 'Please wait...',
              city: 'Please wait...',
            }}
          />
        </div>
      )}

      {/* Render the list of videos */}
      {Array.isArray(state.videoList) &&
        state.videoList.map((item, i) => (
          <div className={classes.videoListItem} key={item.videoId}>
            <Video data={item} />
          </div>
        ))}

      {/* Pagination Controls */}
      <div className={classes.paginationControls}>
        <button onClick={previousPage} disabled={pageNum === 1 || loading}>
          Previous
        </button>
        <span>
          Page {pageNum} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={pageNum === totalPages || loading}>
          Next
        </button>
      </div>
    </div>
  );
};

export default VideoList;